// ICI LE SCRIPT DE LA PAGE
const selecteur = {
    // execute in document.ready
    init: function () {
        console.log("selecteur ready");

        if ($('.selecteur').length) {
            $(".selecteur").on("click", function () {
                $(".selecteur-choix", this).addClass("actif");
            });

            $(".selecteur-choix").on("mouseleave", function () {
                $(this).removeClass("actif");
            });

            // Gestionnaire d'événements pour cliquer en dehors
            $(document).on("click", function (event) {
                // Vérifie si le clic a eu lieu en dehors de .selecteur
                if (!$(event.target).closest('.selecteur').length) {
                    $(".selecteur-choix").removeClass("actif"); // Cache l'élément actif
                }
            });
        }
    }
}
export { selecteur };