// IMPORT VENDOR
import Swiper from 'swiper';
// IMPORT COMPONENTS
/*
 * Chemin relatif au fichier dans lequel on est
 * Ex : import {utils} from '../components/_utils';
 */

const homepage = {

    readyFn: function () {
        let self = this;
        self.setswiper();

    },

    loadFn: function () {
        // Ici le script qui sera exécuté au Window Load
    },

    setswiper: function () {
        var slidesFullView;
        var widthScreen = jQuery(window).width();

        if (widthScreen < 1024) {
            slidesFullView = 1.1;
        } else {
            slidesFullView = 3.5;
        }
        const swiper = new Swiper('.swiper', {
            // Optional parameters
            slidesPerView: slidesFullView,
            spaceBetween: 20,
        });


    }
}

export {
    homepage
};