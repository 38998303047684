import { Accordion } from 'foundation-sites/js/foundation.accordion';
Foundation.plugin(Accordion, 'Accordion');

// ICI LE SCRIPT DE LA PAGE
const accordion = {
    // execute in document.ready
    init: function () {
        console.log("accordion ready");
        var elem = new Foundation.Accordion($('#main-content .accordion'));
    }
}
export { accordion };