// --- Vendors ---
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');

// --- Components ---
const filtre = {

    readyFn: function () {
        let self = this;
        jQueryBridget('isotope', Isotope, $);
        this.activite();
        console.log("tax detected")

    },

    activite: function () {

        var $gridActivite = $('.grid-activite').isotope({
            itemSelector: '.cell-single',
            layoutMode: 'fitRows'
        });

        $(".tag-list .tag").on("click", function () {
            $(".tag-list .tag").removeClass("actif");
            $(this).addClass("actif");
            var filterValue = "";
            $(".tag-list .tag.actif").each(function () {
                filterValue += $(this).attr("data-filter");
            });
            if (filterValue == null || !filterValue) {
                $(".tag.all").addClass("actif");
            }
            $gridActivite.isotope({
                filter: filterValue
            });
            console.log("filterValues : " + filterValue);
        });

        $("#filtre-activite").on("change", function () {
            var filterValue = $(this).val();
            $gridActivite.isotope({
                filter: filterValue
            });
        });
    },


}
export { filtre };