// --- Vendors ---

// --- Components ---
const footer = {

    readyFn: function () {
        let self = this;
        this.footer();
    },

    footer: function () {
        console.log("Footer Ready");
        if ($(window).width() < 1024) {
            $(".menu-item-has-children").on("click", function () {
                $(this).toggleClass("actif");
                $(".sub-menu", this).slideToggle();
            });
        }
    },


}
export { footer };