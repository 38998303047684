// --- Vendors ---
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');

// --- Components ---
const filtreListing = {

    readyFn: function () {
        let self = this;
        jQueryBridget('isotope', Isotope, $);
        this.activite();
        console.log("tax detected")

    },

    activite: function () {

        var $gridActivite = $('.grid-activite').isotope({
            itemSelector: '.cell-single',
            layoutMode: 'fitRows'
        });

        var value = "";
        var filterValue = "";
        var filterValueSelect = "";

        $(".tag-list .tag").on("click", function () {
            $(".tag-list .tag").removeClass("actif");
            $(this).addClass("actif");
            if (filterValue == null || !filterValue) {
                $(".tag.all").addClass("actif");
            }
            $(".tag-list .tag.actif").each(function () {
                filterValue += $(this).attr("data-filter");
            });
            value = filterValue + filterValueSelect;
            $gridActivite.isotope({
                filter: value
            });
            console.log("filterValues : " + filterValue);
            value = filterValue;
        });

        $("#filtre-age").on("change", function () {
            var filterValueSelect = $(this).val();
            value = filterValueSelect + filterValue;
            $gridActivite.isotope({
                filter: value
            });
        });

        $("#filtre-activite").on("change", function () {
            var filterValue = $(this).val();
            $gridActivite.isotope({
                filter: filterValue
            });
        });


    },


}
export { filtreListing };