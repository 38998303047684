// scroll-to-link.js

export default class ScrollToLink {
    constructor() {
        // Optionnel : initialisation des propriétés si besoin
    }

    init() {
        let self = this;

        // Vérifie s'il y a une ancre dans l'URL lors du chargement de la page
        document.addEventListener("DOMContentLoaded", () => {
            let currentURL = window.location.href;
            if (currentURL.includes('#')) {
                let link = currentURL.split('#')[1]; // Récupère l'ancre après le #
                if (link) {
                    self.scrollTo(`#${link}`);
                }
            }

            // Ajoute un événement click aux liens avec la classe "scroll-to"
            $('.scroll-to').on('click', (e) => {
                e.preventDefault();

                // Récupère le href du lien cliqué
                let link = e.currentTarget.getAttribute('href');
                if (link) {
                    self.scrollTo(link);
                }
            });
        });
    }

    scrollTo(link) {
        console.log(`Attempting to scroll to: ${link}`);

        // Vérifie si l'élément existe avant de scroller
        if ($(link).length > 0) {
            // Récupère la hauteur de l'en-tête (ou 0 par défaut si inexistant)
            let headerHeight = document.querySelector('header')?.offsetHeight || 0;

            // Animation de défilement fluide
            $('html, body').animate({
                scrollTop: ($(link).offset().top - headerHeight)
            }, 400);
        } else {
            console.warn(`Element not found: ${link}`);
        }
    }
}

// Initialisation de la classe
const scrollToLink = new ScrollToLink();
scrollToLink.init();
